<template>
	<el-table-column label="视频" min-width="170" align="center">
		<template slot-scope="scope">
			<template v-if="scope.row.isEdit && !scope.row.videoLink">
				<div v-if="scope.row.record.videoStat.onUpload" class="video-upload-progress-bar-container">
					<el-progress class="w-100" :text-inside="true" :stroke-width="10" :percentage="scope.row.record.videoStat.progress"></el-progress>
				</div>
				<div v-else :id="'editor-video-'+scope.row.id" style="width:160px;height:90px;position:relative" class="editor-video-box">
					<video v-if="scope.row.record.videoLink" width="160" height="90" controls @play="videoPlay">
					    <source :src="scope.row.record.videoLink">
						Sorry, your browser doesn't support embedded videos.
					</video>
					<div v-else>
						<el-button type="text" @click="beforeUpload(scope.row.record.file, scope.row)">重新上传</el-button>
					</div>
				</div>
				<img class="d-none" src="@/assets/images/default.png" @load="beforeUpload(scope.row.record.file, scope.row)" />
			</template>
			<template v-else>
				<div v-if="scope.row.videoLink" style="width:160px;height:90px;">
					<video controls width="160" height="90" @play="videoPlay">
					    <source :src="scope.row.videoLink">
						Sorry, your browser doesn't support embedded videos.
					</video>
				</div>
				<div v-else style="height:90px;line-height:90px;">-</div>
			</template>
		</template>
	</el-table-column>
</template>

<script>
	import {fetchJSONByPost} from '@/apis'
	export default {
		methods: {
			beforeUpload(file, row) {
				row.record.videoStat.progress = 0
				row.record.videoStat.onUpload = true
				const data = new FormData()
				data.append("file", file, file.name)
				fetchJSONByPost('/adms_api_v0/media/file', data, {
					onUploadProgress: event => {
						const {loaded, total} = event
						row.record.videoStat.progress = Math.floor(loaded * 100 / total)
					}
				})
				.then(data => {
					row.record.videoStat.onUpload = false
					row.record.videoLink = data.src
				})
				.catch(err => {
					row.record.videoStat.onUpload = false
					this.$showErrMsg(err)
				})
			},
			videoPlay(event){
				const videos = document.querySelectorAll('video')
				Array.from(videos).forEach(function(el, index) {
					if (el !== event.target) {
						el.pause()
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	.video-editor-picker {
		width: 100%;
		height: 100%;
		div.el-upload {
			width: 100%;
			height: 100%;
			.el-upload--picture-card {
				width: 160px;
				height: 90px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.video-upload-progress-bar-container {
		height: 90px;
		display: flex;
		align-items: center;
		.el-progress-bar__innerText {
			font-size: 10px;
			transform: translateY(-2px);
		}
	}
	.editor-video-box {
		&>div {
			height:100%;
		}
		.el-button {
			height: 100%;
		}
	}
</style>
