import SiteTableColumn from './site.vue'
import LaunguageTableColumn from './language.vue'
import CategoryTableColumn from './category.vue'
import VideoNameTableColumn from './video-name.vue'
import VideoTableColumn from './video.vue'
import CoverImageTableColumn from './cover-image.vue'
import SpuTableColumn from './spu.vue'
import FinalUrlTableColumn from './final-url.vue'
export default {
	SiteTableColumn,
	LaunguageTableColumn,
	CategoryTableColumn,
	VideoNameTableColumn,
	VideoTableColumn,
	CoverImageTableColumn,
	SpuTableColumn,
	FinalUrlTableColumn
}