<template>
	<el-table height="auto" ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange" @select="pinSelect" size="mini" row-key="id" :row-class-name="tableRowClassName">
		<el-table-column type="selection" width="55" :selectable="rowSelectable">
		</el-table-column>
		<el-table-column label="时间" min-width="100">
			<template slot-scope="scope">
				{{scope.row.createdAt | formatDate('YYYY-MM-DD')}}
			</template>
		</el-table-column>
		<component v-for="tableColumn in tableColumns" v-bind:is="tableColumn">
		</component>
		<el-table-column label="操作" min-width="150">
			<template slot-scope="scope">
				<el-button v-if="scope.row.isEdit"  class="text-primary confirm-btn" type="text" @click="doAction('update', scope.row, scope.$index)">确认</el-button>
				<el-button v-else class="text-primary" type="text" @click="doAction('edit', scope.row, scope.$index)">编辑</el-button>
				<el-button class="text-danger" type="text" @click="doAction('delete', scope.row, scope.$index)">删除</el-button>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	import TableColumns from './table-column/index.js'
	export default {
		mixins: [MultipleTableSelection],
		inject: ['adminLayout'],
		components: {
			...TableColumns
		},
		mounted() {
			this.tableColumns = Object.keys(TableColumns)
		},
		props: {
			tableData: {
				type: Array,
				default: []
			}
		},
		data() {
			return {
				tableColumns: [],
				namespace: 'fb-video-ads'
			}
		},
		methods: {
			rowSelectable(row, index) {
				return row.videoLink && row.coverImageLink && !row.isEdit
			},
			tableRowClassName({row, rowIndex}) {
				if (/^\d+$/.test(row.id)) {
					return ''
				}
				return 'warning-row'
			},
			doAction(action, row, index) {
				switch (action) {
					case 'edit':
						this.$refs.multipleTable.toggleRowSelection(row, false)
						row.isEdit = true
						this.$set(row, 'record', {
							...row,
							imageStat: {
								progress: 0,
								onUpload: false
							},
							videoStat: {
								progress: 0,
								onUpload: false
							}
						})
						break
					case 'update':
						if (row.record.imageStat.onUpload || row.record.videoStat.onUpload) {
							return this.$showWarning('多媒体在上传中...')
						}
						if (row.record.videoLink === '') {
							return this.$showWarning('视频还未上传成功，请先完成上传')
						}
						if (row.record.websiteCode === '') {
							return this.$showErrMsg('站点不能为空')
						}
						if (row.record.languageCode === '') {
							return this.$showErrMsg('语言不能为空')
						}
						if (row.record.category === '') {
							return this.$showErrMsg('品类不能为空')
						}
						const spus = row.record.spu.match(/[\w-]+/g) || []
						const link_valid = /^(http|https):\/\//.test(row.record.productLink)
						if (!link_valid && row.record.productLink !== '') {
							return this.$showErrMsg('关联的url不是一个正确的URL格式')
						}
						this.adminLayout.showLoading()
						const body = {
							...row.record,
							spu: spus.join(',')
						}
						const update = /^\d+$/.test(row.id)
						const payload = update ? {id: row.id, body: {...body, file: null, imgFile: null}} : [{...body, file: null, imgFile: null}]
						const dispatchAction = update ? 'saveItem' : 'newItem'
						this.$store.dispatch(`${this.namespace}/${dispatchAction}`, payload)
						.then(res => {
							row.isEdit = false
							Object.assign(row, res[0])
							this.adminLayout.hideLoading()
						})
						.catch(err => {
							this.adminLayout.hideLoading()
							this.$showErrMsg(err)
						})
						break
					case 'delete':
						this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							if (/^\d+$/.test(row.id)) {
								this.adminLayout.showLoading()
								this.$store.dispatch(`${this.namespace}/delItems`, [row.id])
								.then(res => {
									this.adminLayout.hideLoading()
									this.tableData.splice(index, 1)
								})
								.catch(err => {
									this.adminLayout.hideLoading()
									this.$showErrMsg(err)
								})
							} else {
								this.tableData.splice(index, 1)
							}
						})
						break
				}
			}
		}
	}
</script>
<style lang="scss">
	.confirm-btn {
		animation: popup-btn 0.5s linear infinite;
	}
	@keyframes popup-btn {
		0% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.2);
		}
		100% {
			transform: scale(1);
		}
	}
</style>