<template>
		<el-card>
			<advanced-search-bar>
				<template>
					<form-item-sites :abbr="true" :multiple="false" :local="true" v-model="queryForm.websiteCode" :parent="false" @onload="q.websiteCode=queryForm.websiteCode;searchEvent()" :clearable="false" @siteFullName="websiteFullName=$event"></form-item-sites>
					<form-item>
						<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">搜 索</el-button>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" @click="exportData">下 载</el-button>
					</form-item>
					<form-item>
						<el-popover
						    width="400"
						    trigger="hover"
						    >
						    <ul class="m-0">
								<li>文件大小: 最大50MB</li>
								<li>文件格式: <span class="text-success">.MOV</span> & <span class="text-success">.MP4</span></li>
								<li>分辨率: 至少720p</li>
								<li>横纵比: 至少16:9</li>
								<li>文件名格式: 站点_语言_品类_p商品id1+商品id2_其它</li>
								<li style="list-style:none;">
									<el-link :underline="false" type="primary" target="_blank" href="https://developers.facebook.com/docs/app-ads/formats/video-ad">更多详情</el-link>
								</li>
							</ul>
							<el-button type="primary" slot="reference" size="mini" @click="clickUpload">上传视频</el-button>
						  </el-popover>
					</form-item>
				</template>
				<template #advanced>
					<form-item-languages v-model="queryForm.languageCode"></form-item-languages>
					<form-item-categories v-model="queryForm.category" :site="websiteFullName"></form-item-categories>
					<form-item label="视频名称:">
						<el-input v-model.trim="queryForm.videoName" placeholder="请输入" size="mini" clearable></el-input>
					</form-item>
					<form-item label="商品id:">
						<el-input v-model="queryForm.spu" placeholder="请输入" size="mini" clearable></el-input>
					</form-item>
					<form-item label="选择日期:" style="width:400px;">
						<el-date-picker v-model="queryForm.date" type="daterange" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:340px;" size="mini" :clearable="false">
						</el-date-picker>
					</form-item>
				</template>
			</advanced-search-bar>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<div class="d-flex flex-row">
				<el-button type="primary" size="mini" @click="doPreviewAds('config')" class="mr-2">预览广告</el-button>
			</div>
			<alert v-if="multipleSelection.length > 0" class="my-2">
				已选择<span class="text-primary d-inline-block mx-1">{{multipleSelection.length}}个视频</span>
			</alert>
			<videos-table ref="videos-table" :tableData="tableData" @handleSelectionChange="handleSelectionChange" class="mt-3 w-100"></videos-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
			<ads-config-dialog :visible.sync="visible.adsConfig" @callback="(form)=>doPreviewAds('preview', form)" buttonTitle="视频">
			</ads-config-dialog>
			<ads-preview-dialog ref="preview-dialog" :visible.sync="visible.preview" :data="previewData" @callback="()=>doPreviewAds('publish')" title="视频" columnLabel2="视频广告信息">
			</ads-preview-dialog>
			<el-upload
				v-show="false"
				:show-file-list="false"
				action="/adms_api_v0/"
				accept="video/mp4,video/mov,video/quicktime"
				:before-upload="beforeUpload"
				id="videos-upload"
				multiple
				>
			</el-upload>
		</el-card>
</template>

<script>
	import Moment from 'moment'
	import Page from '@/common/mixins/page.js'
	import Common from '@/common/mixins/common.js'
	import Dialogs from '@/components/dialog'
	import VideosTable from '@/components/videos/index.vue'
	import {uuid} from 'vue-uuid'
	export default {
		mixins: [Page, Common],
		inject: ['adminLayout'],
		components: {
			...Dialogs,
			VideosTable
		},
		data() {
			return {
				queryForm: {
					websiteCode: '',
					spu: '',
					category: '',
					date: [
						Moment().startOf('day').format('YYYY-MM-DD'),
						Moment().endOf('day').format('YYYY-MM-DD')
					],
					languageCode: '',
					videoName: ''
				},
				websiteFullName: '',
				q: {
					websiteCode: '',
					createdAtMin: Moment().startOf('day').format('YYYY-MM-DD'),
					createdAtMax: Moment().endOf('day').format('YYYY-MM-DD')
				},
				tableData: [],
				visible: {
					adsConfig: false,
					preview: false
				},
				previewData: {},
				fetchOnCreated: false,
				namespace: 'fb-video-ads'
			}
		},
		methods: {
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				const payload = {
					params: {
						...this.page,
						...this.q
					},
					trigger
				}
				return this.$store.dispatch(`${this.namespace}/getList`, payload)
			},
			handleList(content) {
				this.tableData = content.map(v => {
					return {
						...v,
						isEdit: false
					}
				})
			},
			// Button Events
			doPreviewAds(type = 'config', data = null) {
				if (this.multipleSelection.length === 0) {
					return this.$showErrMsg('请先选择商品')
				}
				switch (type) {
					case 'config':
						this.visible.adsConfig = true
						break
					case 'preview':
						this.visible.adsConfig = false
						this.adminLayout.showLoading()
						const payload = {
							...data,
							websiteCode: this.q.websiteCode,
							productIds: this.multipleSelection.map(v => {
								return {
									id: v.id,
									originPic: true
								}
							})
						}
						this.$store.dispatch(`${this.namespace}/preview`, payload)
							.then(res => {
								this.previewData = res
								const {publishType} = data
								if (publishType === 'Normal' || publishType === 'NEW_TEST') {
									this.$set(this.previewData, 'startAt', null)
									this.$set(this.previewData, 'attribution', this.q.websiteCode === 'HKP' ? '1D_CLICK' : '1D_CLICK_OR_VIEW')
								}
								this.$set(this.previewData, 'status', 'PAUSED')
								this.adminLayout.hideLoading()
								this.visible.preview = true
							})
							.catch(err => {
								this.$showErrMsg(err)
								this.adminLayout.hideLoading()
							})
						break
					case 'publish':
						const {startAt} = this.previewData
						if (!!startAt && startAt - new Date().getTime() < 1800*1000) {
							this.$showErrMsg('开始时间需大于当前时间30min+')
							return
						}
						this.adminLayout.showLoading(true)
						console.log(this.$refs)
						this.$store.dispatch(`${this.namespace}/publish`, {id: this.previewData.id, body: this.$refs['preview-dialog'].getOptionsBody()})
							.then(res => {
								this.visible.preview = false
								this.adminLayout.hideLoading()
								this.$refs['videos-table'].$refs.multipleTable.clearSelection()
							})
							.catch(err => {
								this.$showErrMsg(err)
								this.adminLayout.hideLoading()
							})
					default:
						break
				}
			},
			searchEvent() {
				this.q = {
					...this.queryForm,
					createdAtMin: this.queryForm.date[0],
					createdAtMax: this.queryForm.date[1],
					spu: this.queryForm.spu.match(/[\w-]+/g)
				}
				this.page.current = 1
				this.getList()
			},
			clickUpload() {
				document.querySelector('#videos-upload input').click()
			},
			beforeUpload(file) {
				console.log(file, new Date().getTime())
				const canUpload = file.size <= 50 * 1024 * 1024
				// 站点_语言_品类_p商品id1+商品id2_其它
				if (canUpload) {
					const res = /(?<fn>.*)\.(mov|mp4)/ig.exec(file.name)
					if (res) {
						const fn = res.groups.fn
						const regexs = [
							/^(?<site>[A-Z]+)_(?<lang>[A-Z]+)_(?<cate>[A-Za-z&\s]+)_p(?<products>\d+(\+\d+){0,})_(?<others>.*)/g,
							/^(?<site>[A-Z]+)_(?<lang>[A-Z]+)_(?<cate>[A-Za-z&\s]+)_p(?<products>\d+(\+\d+){0,})/g,
							/^(?<site>[A-Z]+)_(?<lang>[A-Z]+)_(?<cate>[A-Za-z&\s]+)/g,
							/^(?<site>[A-Z]+)_(?<lang>[A-Z]+)/g,
							/^(?<site>[A-Z]+)/g
						]
						const defaultVal = {
							site : this.q.websiteCode,
							cate: 'MIX',
							lang: 'EN',
							products: '',
							others: ''
						}
						for (const index in regexs) {
							const m = regexs[index].exec(fn)
							if (m !== null) {
								Object.assign(defaultVal, m.groups)
								break
							}
						}
						const id = uuid.v4()
						const createdAt = new Date().getTime()
						this.tableData.unshift({
							id,
							createdAt,
							websiteCode: defaultVal.site,
							languageCode: '',
							videoName: '',
							videoLink: '',
							coverImageLink: '',
							spu: '',
							productLink: '',
							category: '',
							isEdit: true,
							record: {
								id,
								createdAt,
								websiteCode: defaultVal.site,
								languageCode: defaultVal.lang,
								category: defaultVal.cate,
								videoName: file.name,
								videoLink: '',
								coverImageLink: '',
								productLink: '',
								spu: defaultVal.products,
								videoStat: {
									progress: 0,
									onUpload: false
								},
								imageStat: {
									progress: 0,
									onUpload: false
								},
								file
							}
						})
					}
				} else {
					this.$showErrMsg(file.name + '超过50MB，无法上传')
				}
				return false
			},
			async checkItemUnsaved() {
				const unsaved = this.tableData.reduce((lhs, rhs) => (rhs.isEdit || false) || lhs, false)
				return new Promise((resolve, _) => {
					if (unsaved) {
						this.$confirm('有尚未保存的记录, 是否继续?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						})
						.then(() => {
							resolve(true)
						})
						.catch(() => {
							resolve(false)
						})
					} else {
						resolve(true)
					}
				})
			},
			exportData() {
				if (JSON.stringify(this.q) === '{}') return
				this.$store.commit('download-manager/addTask', {
					action: `${this.namespace}/getList`,
					payload: {...this.q},
					title: `FB_VIDEO(${this.q.createdAtMin}~${this.q.createdAtMax})`,
					pagable: {page: 1, size: 50},
					dispatch: this.$store.dispatch,
					requestHandler() {
						const params = Array.from(arguments).reduce((lhs, rhs) => {
							return {...lhs, ...rhs}
						}, {})
						return {params}
					}
				})
			}
		},
		async beforeRouteLeave(to, from, next) {
			if (this.$store.state.user.user === undefined) {
				// User Logout
				next()
				return
			}
			const confirm = await this.checkItemUnsaved()
			if (!confirm) {
				this.adminLayout.activeMenu(from)
				next(false)
				return
			}
			next()
		}
	}
</script>

<style lang="scss">
	.el-table .warning-row {
	    background: oldlace;
	}
</style>

