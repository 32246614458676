<template>
	<el-table-column label="视频封面" min-width="170" align="center">
		<template slot-scope="scope">
			<template v-if="scope.row.isEdit">
				<div v-if="scope.row.record.imageStat.onUpload" class="cover-img-upload-progress-bar-container">
					<el-progress class="w-100" :text-inside="true" :stroke-width="10" :percentage="scope.row.record.imageStat.progress"></el-progress>
				</div>
				<div v-else :id="'editor-cover-image-'+scope.row.id" style="width:160px;height:90px;position:relative;" @click="changeImg('#editor-img-picker-' + scope.row.id + ' input', scope.row.record.coverImageLink)">
					<el-image v-if="scope.row.record.coverImageLink" style="width:100%;height:100%;" fit="contain" :src="scope.row.record.coverImageLink">
					</el-image>
					<el-upload v-show="!scope.row.record.coverImageLink"
						:show-file-list="false"
						action="/adms_api_v0/media/file"
						accept="image/*"
						:before-upload="(file)=>beforeUpload(file, scope.row)"
						:id="'editor-img-picker-' + scope.row.id"
						class="image-editor-picker"
						:on-progress="(event, file, fileList)=>onProgress(event, file, fileList, scope.row)"
						:on-success="(response, file, fileList)=>onSuccess(response, file, fileList, scope.row)"
						:on-error="(err, file, fileList)=>onError(err, file, fileList, scope.row)"
						:headers="headers" >
						<div slot="trigger" class="el-upload--picture-card">
							<i class="el-icon-plus"></i>
						</div>
					</el-upload>
				</div>
			</template>
			<template v-else>
				<div v-if="scope.row.coverImageLink" style="width:160px;height:90px;">
					<el-image style="width:100%;height:100%;" fit="contain" :src="scope.row.coverImageLink"
						:preview-src-list="[scope.row.coverImageLink]">
					</el-image>
				</div>
				<div v-else style="height:90px;line-height:90px;">-</div>
			</template>
			<img v-if="scope.row.record && scope.row.record.file" class="d-none" src="@/assets/images/default.png" @load="captureVideoImg(scope.row)" />
		</template>
	</el-table-column>
</template>

<script>
	import $Util from '@/common/util.js'
	import {fetchJSONByPost} from '@/apis'
	export default {
		computed: {
			headers() {
				const Authorization = localStorage.getItem('TOKEN')
				return {Authorization}
			}
		},
		methods: {
			captureVideoImg(row) {
				$Util.captureImage(row.record.file)
				.then((imgFile) => {
					row.record.imgFile = imgFile
					this.manuallyUpload(row.record.imgFile, row)
				})
			},
			manuallyUpload(file, row) {
				row.record.imageStat.progress = 0
				row.record.imageStat.onUpload = true
				const data = new FormData()
				data.append("file", file, file.name)
				fetchJSONByPost('/adms_api_v0/media/file', data, {
					onUploadProgress: event => {
						const {loaded, total} = event
					  	row.record.imageStat.progress = Math.floor(loaded * 100 / total)
					}
				})
				.then(data => {
					row.record.imageStat.onUpload = false
					row.record.coverImageLink = data.src
				})
				.catch(err => {
					row.record.imageStat.onUpload = false
					this.$showErrMsg(err)
				})
			},
			beforeUpload(file, row) {
				row.record.imageStat.progress = 0
				row.record.imageStat.onUpload = true
				return true
			},
			changeImg(id, image) {
				if (image) {
					document.querySelector(id).click()
				}
			},
			onProgress(event, file, fileList, row) {
				row.record.imageStat.progress = Math.floor(event.percent)
			},
			onSuccess(response, file, fileList, row) {
				row.record.imageStat.onUpload = false
				if (response.success) {
					row.record.coverImageLink = response.data.src
				} else {
					this.$showErrMsg(response.errorMsg)
				}
			},
			onError(err, file, fileList, row) {
				row.record.imageStat.onUpload = false
				try {
					const errorObject=JSON.parse(JSON.stringify(err));
					const errMsg = errorObject.message
					this.$showErrMsg(errMsg)
				} catch (e) {
					console.error(e)
				}
				
			}
		}
	}
</script>

<style lang="scss">
	.image-editor-picker {
		width: 100%;
		height: 100%;
		div.el-upload {
			width: 100%;
			height: 100%;
			.el-upload--picture-card {
				width: 160px;
				height: 90px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.cover-img-upload-progress-bar-container {
		height: 90px;
		display: flex;
		align-items: center;
		.el-progress-bar__innerText {
			font-size: 10px;
			transform: translateY(-2px);
		}
	}
	.cover-image-action-btn {
		position: absolute;
		top: 0;
		right: 0;
	}
</style>
